import {
  BannerData,
  CountryOption,
  GuardAction,
  RegionEnum,
  RewardCustomizationValues,
  UserPermissions,
  WistiaVideoEmbedOptions,
} from "./types";
import previewHref from "@two-ui/assets/redemption-preview.png";
import { countries } from "countries-list";
import { getBaseUrl } from "./util/url";
import { SelectOption } from "@wegift/two-components";
import { SideBarItemsType } from "./types";
import Item from "./components/common/timeline/Item.vue";
import { UserRoleEnum } from "@workspace/open-api/cms";

export const BASE_URL = getBaseUrl();

export const SIDE_BAR_ITEMS: SideBarItemsType[] = [
  {
    routeName: "home",
    displayName: "Home",
    icon: ["fas", "home"],
  },
  {
    routeName: "runa-network",
    displayName: "Network",
    icon: ["fas", "list"],
    children: [
      {
        routeName: "product-catalog-home",
        displayName: "Merchants",
        icon: [],
        permissionGuards: {
          permissions: [UserPermissions.READ_PRODUCTS],
          guardAction: GuardAction.AND,
        },
      },
      {
        routeName: "product-approval-home",
        displayName: "Approvals",
        icon: [],
        permissionGuards: {
          permissions: [UserPermissions.READ_PRODUCT_APPROVALS],
          guardAction: GuardAction.AND,
        },
      },
    ],
    permissionGuards: {
      permissions: [
        UserPermissions.READ_PRODUCTS,
        UserPermissions.READ_PRODUCT_APPROVALS,
      ],
      guardAction: GuardAction.OR,
    },
  },
  {
    routeName: "orders",
    displayName: "Order history",
    icon: ["far", "receipt"],
    permissionGuards: {
      permissions: [UserPermissions.READ_ORDERS],
      guardAction: GuardAction.AND,
    },
  },
  {
    routeName: "billing",
    displayName: "Billing & Finance",
    icon: ["far", "bank"],
    permissionGuards: {
      permissions: [
        UserPermissions.READ_BILLING,
        UserPermissions.READ_STATEMENTS,
        UserPermissions.READ_ACTIVITY_HISTORY,
        UserPermissions.WRITE_TOP_UP_BY_FX,
      ],
      guardAction: GuardAction.OR,
    },
    children: [
      {
        routeName: "billing-home",
        displayName: "Billing overview",
        icon: [],
        permissionGuards: {
          permissions: [UserPermissions.READ_BILLING],
          guardAction: GuardAction.AND,
        },
      },
      {
        routeName: "statements-home",
        displayName: "Statements",
        icon: [],
        permissionGuards: {
          permissions: [UserPermissions.READ_STATEMENTS],
          guardAction: GuardAction.AND,
        },
      },
      {
        routeName: "transactions-home",
        displayName: "All transactions",
        icon: [],
        permissionGuards: {
          permissions: [UserPermissions.READ_ACTIVITY_HISTORY],
          guardAction: GuardAction.AND,
        },
      },
      {
        routeName: "currency-exchange",
        displayName: "Runa FX",
        icon: [],
        permissionGuards: {
          permissions: [UserPermissions.WRITE_TOP_UP_BY_FX],
          guardAction: GuardAction.AND,
        },
      },
      {
        routeName: "stored-cards",
        displayName: "Stored cards",
        icon: [],
        isUserStaff: false,
        permissionGuards: {
          permissions: [UserPermissions.READ_PAYMENT_CARDS],
          guardAction: GuardAction.AND,
        },
      },
    ],
  },

  {
    routeName: "team-members",
    displayName: "Team",
    icon: ["far", "user"],
    permissionGuards: {
      permissions: [UserPermissions.READ_TEAM_MEMBERS],
      guardAction: GuardAction.AND,
    },
  },
  {
    routeName: "api-keys",
    displayName: "API",
    icon: ["far", "puzzle-piece-simple"],
    isUserStaff: false,
    permissionGuards: {
      permissions: [UserPermissions.READ_API_KEYS],
      guardAction: GuardAction.AND,
    },
  },
  {
    routeName: "pending-customers",
    displayName: "Customers",
    icon: ["far", "user-shield"],
    propertyStore: "staffViews",
    isUserStaff: true,
  },
  {
    routeName: "debug",
    displayName: "Debug",
    icon: ["far", "bug"],
    propertyStore: "debugViews",
    isUserStaff: true,
  },
  {
    routeName: "debug-component-library",
    displayName: "Component Library",
    icon: ["far", "shapes"],
    propertyStore: "designViews",
    isUserStaff: true,
  },
  {
    routeName: "debug-theme",
    displayName: "Design Theme",
    icon: ["far", "swatchbook"],
    propertyStore: "designViews",
    isUserStaff: true,
  },
  {
    routeName: "customization",
    displayName: "Customization",
    icon: ["far", "pen"],
    propertyStore: "customization",
    children: [
      {
        routeName: "user-redemption-home",
        displayName: "User redemption",
        icon: [],
      },
      {
        routeName: "payout-selection-home",
        displayName: "Merchant selection",
        icon: [],
      },
    ],
  },
  {
    routeName: "user-settings",
    displayName: "User Settings",
    icon: ["fas", "gear"],
    propertyStore: "userSettings",
  },
  {
    routeName: "help",
    displayName: "Help",
    icon: ["far", "question-circle"],
  },
];

export enum FeatureFlaggedCurrencies {
  AED = "AED",
  ARS = "ARS",
  BGN = "BGN",
  BRL = "BRL",
  CLP = "CLP",
  COP = "COP",
  EGP = "EGP",
  IDR = "IDR",
  ILS = "ILS",
  INR = "INR",
  MYR = "MYR",
  PEN = "PEN",
  PHP = "PHP",
  SAR = "SAR",
  THB = "THB",
  TRY = "TRY",
  VND = "VND",
}

export const isCurrencyEnabled = (
  currency: string,
  currencyFeatureFlags: Record<FeatureFlaggedCurrencies, boolean> | null
) => {
  if (
    Object.keys(FeatureFlaggedCurrencies).includes(currency) &&
    !currencyFeatureFlags
  ) {
    return false;
  }

  if (
    !Object.keys(FeatureFlaggedCurrencies).includes(currency) ||
    !currencyFeatureFlags
  ) {
    return true;
  }

  return currencyFeatureFlags[currency as FeatureFlaggedCurrencies];
};

export const COUNTRY_CODES: { [key: string]: string } = {
  Australia: "AU",
  Canada: "CA",
  Denmark: "DK",
  Estonia: "EE",
  "United Kingdom": "GB",
  "Hong Kong": "HK",
  Japan: "JP",
  "New Zealand": "NZ",
  Poland: "PL",
  Singapore: "SG",
  "United States": "US",
};

export const ROUTING_CODES: { [key: string]: string } = {
  bsb: "BSB code",
  bank_code: "Bank code",
  branch_code: "Branch code",
  transit_number: "Transit number",
  institution_number: "Financial institution number",
  sort_code: "Sort code",
  ach: "ACH routing number",
  fedwire: "Fedwire routing number",
};
export const SUPPORTED_CARD_PAYMENT_CURRENCIES = ["GBP", "USD", "EUR", "CAD"];

export const VALUE_LINK_SUPPORTED_COUNTRIES: Array<CountryOption> = [
  {
    label: "United Kingdom",
    isoCode: "GB",
    currency: "GBP",
    isoCodeAlpha3: "GBR",
    flag: "🇬🇧",
    currencySymbol: "£",
    currencyFullText: "Great British Pound",
  },
  {
    label: "United States",
    isoCode: "US",
    currency: "USD",
    isoCodeAlpha3: "USA",
    flag: "🇺🇸",
    currencySymbol: "$",
    currencyFullText: "United States Dollar",
  },
  {
    label: "Austria",
    isoCode: "AT",
    currency: "EUR",
    isoCodeAlpha3: "AUT",
    flag: "🇦🇹",
    currencySymbol: "€",
    currencyFullText: "Euro",
  },
  {
    label: "Belgium",
    isoCode: "BE",
    currency: "EUR",
    isoCodeAlpha3: "BEL",
    flag: "🇧🇪",
    currencySymbol: "€",
    currencyFullText: "Euro",
  },
  {
    label: "Canada",
    isoCode: "CA",
    currency: "CAD",
    isoCodeAlpha3: "CAN",
    flag: "🇨🇦",
    currencySymbol: "$",
    currencyFullText: "Canadian Dollar",
  },
  {
    label: "Croatia",
    isoCode: "HR",
    currency: "EUR",
    isoCodeAlpha3: "HRV",
    flag: "🇭🇷",
    currencySymbol: "€",
    currencyFullText: "Euro",
  },
  {
    label: "Finland",
    isoCode: "FI",
    currency: "EUR",
    isoCodeAlpha3: "FIN",
    flag: "🇫🇮",
    currencySymbol: "€",
    currencyFullText: "Euro",
  },
  {
    label: "France",
    isoCode: "FR",
    currency: "EUR",
    isoCodeAlpha3: "FRA",
    flag: "🇫🇷",
    currencySymbol: "€",
    currencyFullText: "Euro",
  },
  {
    label: "Germany",
    isoCode: "DE",
    currency: "EUR",
    isoCodeAlpha3: "DEU",
    flag: "🇩🇪",
    currencySymbol: "€",
    currencyFullText: "Euro",
  },
  {
    label: "Greece",
    isoCode: "GR",
    currency: "EUR",
    isoCodeAlpha3: "GRC",
    flag: "🇬🇷",
    currencySymbol: "€",
    currencyFullText: "Euro",
  },
  {
    label: "Ireland",
    isoCode: "IE",
    currency: "EUR",
    isoCodeAlpha3: "IRL",
    flag: "🇮🇪",
    currencySymbol: "€",
    currencyFullText: "Euro",
  },
  {
    label: "Italy",
    isoCode: "IT",
    currency: "EUR",
    isoCodeAlpha3: "ITA",
    flag: "🇮🇹",
    currencySymbol: "€",
    currencyFullText: "Euro",
  },
  {
    label: "Latvia",
    isoCode: "LV",
    currency: "EUR",
    isoCodeAlpha3: "LVA",
    flag: "🇱🇻",
    currencySymbol: "€",
    currencyFullText: "Euro",
  },
  {
    label: "Lithuania",
    isoCode: "LT",
    currency: "EUR",
    isoCodeAlpha3: "LTU",
    flag: "🇱🇹",
    currencySymbol: "€",
    currencyFullText: "Euro",
  },
  {
    label: "Luxembourg",
    isoCode: "LU",
    currency: "EUR",
    isoCodeAlpha3: "LUX",
    flag: "🇱🇺",
    currencySymbol: "€",
    currencyFullText: "Euro",
  },
  {
    label: "Netherlands",
    isoCode: "NL",
    currency: "EUR",
    isoCodeAlpha3: "NLD",
    flag: "🇳🇱",
    currencySymbol: "€",
    currencyFullText: "Euro",
  },
  {
    label: "Portugal",
    isoCode: "PT",
    currency: "EUR",
    isoCodeAlpha3: "PRT",
    flag: "🇵🇹",
    currencySymbol: "€",
    currencyFullText: "Euro",
  },
  {
    label: "Slovakia",
    isoCode: "SK",
    currency: "EUR",
    isoCodeAlpha3: "SVK",
    flag: "🇸🇰",
    currencySymbol: "€",
    currencyFullText: "Euro",
  },
  {
    label: "Slovenia",
    isoCode: "SI",
    currency: "EUR",
    isoCodeAlpha3: "SVE",
    flag: "🇸🇮",
    currencySymbol: "€",
    currencyFullText: "Euro",
  },
  {
    label: "Spain",
    isoCode: "ES",
    currency: "EUR",
    isoCodeAlpha3: "ESP",
    flag: "🇪🇸",
    currencySymbol: "€",
    currencyFullText: "Euro",
  },
  {
    label: "Argentina",
    isoCode: "AR",
    currency: "ARS",
    isoCodeAlpha3: "ARG",
    flag: "🇦🇷",
    currencySymbol: "$",
    currencyFullText: "Argentine Peso",
  },
  {
    label: "Australia",
    isoCode: "AU",
    currency: "AUD",
    isoCodeAlpha3: "AUS",
    flag: "🇦🇺",
    currencySymbol: "$",
    currencyFullText: "Australian Dollar",
  },
  {
    label: "Brazil",
    isoCode: "BR",
    currency: "BRL",
    isoCodeAlpha3: "BRA",
    flag: "🇧🇷",
    currencySymbol: "R$",
    currencyFullText: "Brazilian Real",
  },
  {
    label: "Bulgaria",
    isoCode: "BG",
    currency: "BGN",
    isoCodeAlpha3: "BGR",
    flag: "🇧🇬",
    currencySymbol: "лв",
    currencyFullText: "Bulgarian Lev",
  },
  {
    label: "Chile",
    isoCode: "CL",
    currency: "CLP",
    isoCodeAlpha3: "CHL",
    flag: "🇨🇱",
    currencySymbol: "$",
    currencyFullText: "Chilean Peso",
  },
  {
    label: "China",
    isoCode: "CN",
    currency: "CNY",
    isoCodeAlpha3: "CHN",
    flag: "🇨🇳",
    currencySymbol: "¥",
    currencyFullText: "Chinese Yuan",
  },
  {
    label: "Columbia",
    isoCode: "CO",
    currency: "COP",
    isoCodeAlpha3: "COL",
    flag: "🇨🇴",
    currencySymbol: "$",
    currencyFullText: "Colombian Peso",
  },
  {
    label: "Czech Republic",
    isoCode: "CZ",
    currency: "CZK",
    isoCodeAlpha3: "CZE",
    flag: "🇨🇿",
    currencySymbol: "Kč",
    currencyFullText: "Czech Koruna",
  },
  {
    label: "Denmark",
    isoCode: "DK",
    currency: "DKK",
    isoCodeAlpha3: "DNK",
    flag: "🇩🇰",
    currencySymbol: "kr",
    currencyFullText: "Danish Krone",
  },
  {
    label: "Egypt",
    isoCode: "EG",
    currency: "EGP",
    isoCodeAlpha3: "EGY",
    flag: "🇪🇬",
    currencySymbol: "£",
    currencyFullText: "Egyptian Pound",
  },
  {
    label: "Hungary",
    isoCode: "HU",
    currency: "HUF",
    isoCodeAlpha3: "HUN",
    flag: "🇭🇺",
    currencySymbol: "Ft",
    currencyFullText: "Hungarian Forint",
  },
  {
    label: "India",
    isoCode: "IN",
    currency: "INR",
    isoCodeAlpha3: "IND",
    flag: "🇮🇳",
    currencySymbol: "₹",
    currencyFullText: "Indian Rupee",
  },
  {
    label: "Indonesia",
    isoCode: "ID",
    currency: "IDR",
    isoCodeAlpha3: "IDN",
    flag: "🇮🇩",
    currencySymbol: "Rp",
    currencyFullText: "Indonesian Rupiah",
  },
  {
    label: "Israel",
    isoCode: "IL",
    currency: "ILS",
    isoCodeAlpha3: "ISR",
    flag: "🇮🇱",
    currencySymbol: "₪",
    currencyFullText: "Israeli Shekel",
  },
  {
    label: "Japan",
    isoCode: "JA",
    currency: "JPY",
    isoCodeAlpha3: "JPN",
    flag: "🇯🇵",
    currencySymbol: "¥",
    currencyFullText: "Japanese Yen",
  },
  {
    label: "Malaysia",
    isoCode: "MY",
    currency: "MYR",
    isoCodeAlpha3: "MYS",
    flag: "🇲🇾",
    currencySymbol: "RM",
    currencyFullText: "Malaysian Ringgit",
  },
  {
    label: "Mexico",
    isoCode: "MX",
    currency: "MXN",
    isoCodeAlpha3: "MEX",
    flag: "🇲🇽",
    currencySymbol: "$",
    currencyFullText: "Mexican Peso",
  },
  {
    label: "New Zealand",
    isoCode: "NZ",
    currency: "NZD",
    isoCodeAlpha3: "NZL",
    flag: "🇳🇿",
    currencySymbol: "$",
    currencyFullText: "New Zealand Dollar",
  },
  {
    label: "Norway",
    isoCode: "NO",
    currency: "NOK",
    isoCodeAlpha3: "NOR",
    flag: "🇳🇴",
    currencySymbol: "kr",
    currencyFullText: "Norwegian Krone",
  },
  {
    label: "Peru",
    isoCode: "PE",
    currency: "PEN",
    isoCodeAlpha3: "PER",
    flag: "🇵🇪",
    currencySymbol: "S/",
    currencyFullText: "Peruvian Sol",
  },
  {
    label: "Philippines",
    isoCode: "PH",
    currency: "PHP",
    isoCodeAlpha3: "PHL",
    flag: "🇵🇭",
    currencySymbol: "₱",
    currencyFullText: "Philippine Peso",
  },
  {
    label: "Poland",
    isoCode: "PL",
    currency: "PLN",
    isoCodeAlpha3: "POL",
    flag: "🇵🇱",
    currencySymbol: "zł",
    currencyFullText: "Polish Złoty",
  },
  {
    label: "Romania",
    isoCode: "RO",
    currency: "RON",
    isoCodeAlpha3: "ROU",
    flag: "🇷🇴",
    currencySymbol: "lei",
    currencyFullText: "Romanian Leu",
  },
  {
    label: "Saudi Arabia",
    isoCode: "SA",
    currency: "SAR",
    isoCodeAlpha3: "SAU",
    flag: "🇸🇦",
    currencySymbol: "﷼",
    currencyFullText: "Saudi Riyal",
  },
  {
    label: "Singapore",
    isoCode: "SG",
    currency: "SGD",
    isoCodeAlpha3: "SGP",
    flag: "🇸🇬",
    currencySymbol: "$",
    currencyFullText: "Singapore Dollar",
  },
  {
    label: "South Africa",
    isoCode: "ZA",
    currency: "ZAR",
    isoCodeAlpha3: "ZAF",
    flag: "🇿🇦",
    currencySymbol: "R",
    currencyFullText: "South African Rand",
  },
  {
    label: "South Korea",
    isoCode: "KR",
    currency: "KRW",
    isoCodeAlpha3: "KOR",
    flag: "🇰🇷",
    currencySymbol: "₩",
    currencyFullText: "South Korean Won",
  },
  {
    label: "Spain",
    isoCode: "ES",
    currency: "EUR",
    isoCodeAlpha3: "ESP",
    flag: "🇪🇸",
    currencySymbol: "€",
    currencyFullText: "Euro",
  },
  {
    label: "Sweden",
    isoCode: "SE",
    currency: "SEK",
    isoCodeAlpha3: "SWE",
    flag: "🇸🇪",
    currencySymbol: "kr",
    currencyFullText: "Swedish Krona",
  },
  {
    label: "Switzerland",
    isoCode: "CH",
    currency: "CHF",
    isoCodeAlpha3: "CHE",
    flag: "🇨🇭",
    currencySymbol: "CHF",
    currencyFullText: "Swiss Franc",
  },
  {
    label: "Thailand",
    isoCode: "TH",
    currency: "THB",
    isoCodeAlpha3: "THA",
    flag: "🇹🇭",
    currencySymbol: "฿",
    currencyFullText: "Thai Baht",
  },
  {
    label: "Turkey",
    isoCode: "TR",
    currency: "TRY",
    isoCodeAlpha3: "TUR",
    flag: "🇹🇷",
    currencySymbol: "₺",
    currencyFullText: "Turkish Lira",
  },
  {
    label: "United Arab Emirates",
    isoCode: "AE",
    currency: "AED",
    isoCodeAlpha3: "ARE",
    flag: "🇦🇪",
    currencySymbol: "د.إ",
    currencyFullText: "UAE Dirham",
  },
  {
    label: "Vietnam",
    isoCode: "VN",
    currency: "VND",
    isoCodeAlpha3: "VNM",
    flag: "🇻🇳",
    currencySymbol: "₫",
    currencyFullText: "Vietnamese Dong",
  },
];

export const SUPPORTED_FLOAT_ACCOUNT_CURRENCIES: {
  code: string;
  title: string;
  flag: string;
  symbol?: string;
}[] = [
  { code: "AED", title: "UAE Dirham", flag: "🇦🇪", symbol: "د.إ" },
  { code: "ARS", title: "Argentine Peso", flag: "🇦🇷", symbol: "$" },
  { code: "AUD", title: "Australian Dollar", flag: "🇦🇺", symbol: "$" },
  { code: "BGN", title: "Bulgarian Lev", flag: "🇧🇬", symbol: "лв" },
  { code: "BRL", title: "Brazilian Real", flag: "🇧🇷", symbol: "R$" },
  { code: "CAD", title: "Canadian Dollar", flag: "🇨🇦", symbol: "$" },
  { code: "CLP", title: "Chilean Peso", flag: "🇨🇱", symbol: "$" },
  { code: "CHF", title: "Swiss Franc", flag: "🇨🇭", symbol: "CHF" },
  { code: "CNY", title: "Chinese Yuan", flag: "🇨🇳", symbol: "¥" },
  { code: "COP", title: "Colombian Peso", flag: "🇨🇴", symbol: "$" },
  { code: "CZK", title: "Czech Koruna", flag: "🇨🇿", symbol: "Kč" },
  { code: "DKK", title: "Danish Krone", flag: "🇩🇰", symbol: "kr" },
  { code: "EGP", title: "Egyptian Pound", flag: "🇪🇬", symbol: "£" },
  { code: "EUR", title: "Euro", flag: "🇪🇺", symbol: "€" },
  { code: "GBP", title: "Great British Pound", flag: "🇬🇧", symbol: "£" },
  { code: "HUF", title: "Hungarian Forint", flag: "🇭🇺", symbol: "Ft" },
  { code: "IDR", title: "Indonesian Rupiah", flag: "🇮🇩", symbol: "Rp" },
  { code: "INR", title: "Indian Rupee", flag: "🇮🇳", symbol: "₹" },
  { code: "ILS", title: "Israeli Shekel", flag: "🇮🇱", symbol: "₪" },
  { code: "JPY", title: "Japanese Yen", flag: "🇯🇵", symbol: "¥" },
  { code: "KRW", title: "Korean Won", flag: "🇰🇷", symbol: "₩" },
  { code: "MXN", title: "Mexican Peso", flag: "🇲🇽", symbol: "$" },
  { code: "MYR", title: "Malaysian Ringgit", flag: "🇲🇾", symbol: "RM" },
  { code: "NOK", title: "Norwegian Krone", flag: "🇳🇴", symbol: "kr" },
  { code: "NZD", title: "New Zealand Dollar", flag: "🇳🇿", symbol: "$" },
  { code: "PEN", title: "Peruvian Sol", flag: "🇵🇪", symbol: "S/" },
  { code: "PHP", title: "Philippine Peso", flag: "🇵🇭", symbol: "₱" },
  { code: "PLN", title: "Polish Złoty", flag: "🇵🇱", symbol: "zł" },
  { code: "RON", title: "Romanian Leu", flag: "🇷🇴", symbol: "lei" },
  { code: "SEK", title: "Swedish Krona", flag: "🇸🇪", symbol: "kr" },
  { code: "SAR", title: "Saudi Riyal", flag: "🇸🇦", symbol: "﷼" },
  { code: "SGD", title: "Singapore Dollar", flag: "🇸🇬", symbol: "S$" },
  { code: "THB", title: "Thai Baht", flag: "🇹🇭", symbol: "฿" },
  { code: "TRY", title: "Turkish Lira", flag: "🇹🇷", symbol: "₺" },
  { code: "USD", title: "United States Dollar", flag: "🇺🇸", symbol: "$" },
  { code: "VND", title: "Vietnamese Dong", flag: "🇻🇳", symbol: "₫" },
  { code: "ZAR", title: "South African Rand", flag: "🇿🇦", symbol: "R" },
];

export const RUNA_TERMS_AND_CONDITIONS_URL = "https://wegift.io/terms-of-use/";

export const RUNA_PRIVACY_POLICY_URL = "https://wegift.io/privacy-policy/";

export const getCountryFromIsoCode = (isoCode: string): CountryOption => {
  const country = VALUE_LINK_SUPPORTED_COUNTRIES.find(
    (country) => country.isoCode === isoCode
  );
  if (country === undefined) {
    throw new Error(`Country with isoCode ${isoCode} not found`);
  }
  return country;
};

export const getCurrencyCodeFromCountryIsoCode = (isoCode: string): string =>
  getCountryFromIsoCode(isoCode).currency;

export const PREVIEW_REDEMPTION_HREF = previewHref;

export const RELEASE_BRANCH = "release";

// we have same limit for description length on backend
export const ORDER_DESCRIPTION_MAX_LENGTH = 128;

export const SUPPORT_EMAIL = "ordersupport@runa.io";
export const COMPLIANCE_EMAIL = "compliance@runa.io";

// These countries are 'promoted' to the top of the ALL_COUNTRIES_SELECT_OPTIONS list
const PROMOTED_COUNTRIES = ["US", "GB"];
// These countries are not allowed to be selected due to being on the OFAC list
export const PROHIBITED_COUNTRIES = ["CU", "IR", "KP", "RU", "SY"];

// List of all countries for use in TwoSelectField
export const ALL_COUNTRIES_SELECT_OPTIONS: SelectOption[] = Object.entries(
  countries
)
  .map(
    ([countryCode, country]): SelectOption => ({
      id: countryCode,
      label: country.name,
      value: countryCode,
    })
  )
  .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
  .filter((country) => !PROHIBITED_COUNTRIES.includes(country.value))
  .reduce((acc, curr) => {
    if (PROMOTED_COUNTRIES.includes(curr.value)) {
      return [curr, ...acc];
    }
    return [...acc, curr];
  }, [] as SelectOption[]);

export const getAllCountriesFromIsoCode = (isoCode: string): string => {
  const country = ALL_COUNTRIES_SELECT_OPTIONS.find(
    (country) => country.id === isoCode
  );
  if (country === undefined) {
    throw new Error(`Country with isoCode ${isoCode} not found`);
  }
  return country.label;
};

export const WISTIA_VIDEO_EMBEDS: { [key: string]: WistiaVideoEmbedOptions } = {
  homePageIntro: {
    link: "https://fast.wistia.com/embed/medias/rna4zzfbys.jsonp",
    linkClass: "wistia_async_rna4zzfbys",
    containerHash: "rna4zzfbys",
    title: "Homepage product walk through",
  },
  whatIsRuna: {
    link: "https://fast.wistia.com/embed/medias/31jx6blqhq.jsonp",
    linkClass: "wistia_async_31jx6blqhq",
    containerHash: "31jx6blqhq",
    title: "What is Runa platform",
  },
  creatingAndSendingReward: {
    link: "https://fast.wistia.com/embed/medias/mudiar4iex.jsonp",
    linkClass: "wistia_async_mudiar4iex",
    containerHash: "mudiar4iex",
    title: "Creating and sending rewards",
  },
  redemptionExperience: {
    link: "https://fast.wistia.com/embed/medias/rl75hgdkwr.jsonp",
    linkClass: "wistia_async_rl75hgdkwr",
    containerHash: "rl75hgdkwr",
    title: "Redemption experience",
  },
};

// Order limits.
// The maximum link value for MPLs is 100000 to account for the highest value product in the catalog.
// The maximum link value for SPLs is determined by the product that is selected.
export const MAX_ORDER_LINK_VALUE = 100000;
export const MIN_ORDER_LINK_VALUE = 1;
export const MAX_ORDER_ITEM_COUNT = 350;

// Order defaults.
export const DEFAULT_ORDER_LINKS_QUANTITY = 0;
export const DEFAULT_ORDER_AMOUNT = 10;
export const DEFAULT_ORDER_CURRENCY = "USD";
export const DEFAULT_ORDER_COUNTRY_NAME = "United States";
export const DEFAULT_REWARD_CUSTOMIZATION_VALUES: RewardCustomizationValues = {
  header: "Happy Birthday!",
  message:
    "Hi there! \nHope you have a great birthday, hope you enjoy your gift!",
  colour: "#000000",
};

export const FAQ_LINK =
  "https://runapay.zendesk.com/hc/en-gb/categories/6658389000477-Have-a-free-Runa-account-";

export const ROLES_MATRIX_URL =
  "https://runapay.zendesk.com/hc/en-gb/articles/16645897726237-What-permissions-are-available-for-each-team-role";

export const ROLES_REQUEST_FORM_URL =
  "https://docs.google.com/forms/d/e/1FAIpQLScs7PBONdn9v--X_wGzHDh8rEY_7ywWQHxfwG6AzSwifnWPBQ/viewform?usp=sf_link";

export const COMING_UP_URL = "https://developer.runa.io/page/network-changelog";

export const BOOK_A_DEMO_URL = "https://runa.io/demo";

export const companySizeOptions = [
  { label: "Less than 50 employees", value: "Less than 50 employees" },
  { label: "51- 200 employees", value: "51- 200 employees" },
  { label: "201- 500 employees", value: "201- 500 employees" },
  { label: "501 - 1000 employees", value: "501 - 1000 employees" },
  { label: "More than 1000 employees", value: "More than 1000 employees" },
];

export const rewardNeedOptions = [
  { label: "Today", value: "Today" },
  { label: "Within 7 days", value: "Within 7 days" },
  { label: "Within 30 days", value: "Within 30 days" },
  { label: "Within 90 days", value: "Within 90 days" },
  { label: "I don't know", value: "I don't know" },
];

export const RISKIFIED_SESSION_ID_KEY = "riskifiedSessionId";

export enum OrderDeliveryType {
  Email = "email",
  Link = "link",
}

// regular expressions
export const VALID_EMAIL_REGEX = /^\S+@\S+\.[A-Za-z]{2,}$/;
export const VALID_URL_REGEX =
  /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)$/;

export const EmailStatusMap: { [key: string]: string } = {
  "email-queued": "E-mail queued",
  "email-sent": "E-mail sent",
};

// How often to poll when waiting for order to complete
export const ORDER_IS_COMPLETE_POLL_INTERVAL = 1000;

// Intervals between polls for pending customer onboarding task state. Polling will end when all intervals are used
export const PENDING_CUSTOMER_ONBOARDING_STATE_POLL_INTERVALS = [
  10_000, 15_000, 20_000,
];

// Show some constants in the debug view
export const DEBUG_CONSTANTS = {
  BASE_URL,
  companySizeOptions,
  DEFAULT_ORDER_AMOUNT,
  DEFAULT_ORDER_COUNTRY_NAME,
  DEFAULT_ORDER_CURRENCY,
  DEFAULT_ORDER_LINKS_QUANTITY,
  DEFAULT_REWARD_CUSTOMIZATION_VALUES,
  EmailStatusMap,
  FAQ_LINK,
  MAX_ORDER_LINK_VALUE,
  MAX_ORDER_ITEM_COUNT,
  ORDER_DESCRIPTION_MAX_LENGTH,
  ORDER_IS_COMPLETE_POLL_INTERVAL,
  PREVIEW_REDEMPTION_HREF,
  PROMOTED_COUNTRIES,
  RELEASE_BRANCH,
  rewardNeedOptions,
  SUPPORT_EMAIL,
  VALUE_LINK_SUPPORTED_COUNTRIES,
  RUNA_PRIVACY_POLICY_URL,
  RUNA_TERMS_AND_CONDITIONS_URL,
  WISTIA_VIDEO_EMBEDS,
};

export const ORDER_SOURCE_UI_INDIVIDUAL = "UI_INDIVIDUAL";
export const ORDER_SOURCE_UI_BULK = "UI_BULK";

export const PAYOUT_LINK_PREFIX = "WG-SL";

// Number of decimal places to show wherever we show discounts
export const DISPLAY_DISCOUNT_MAX_DECIMAL_PLACES = 2;
export const DISPLAY_CARD_FEE_MAX_DECIMAL_PLACES = 2;

// An enum of internal error response texts in returned by Runa APIs, mostly in type field in error response body.
export enum ApiErrorResponseTexts {
  OrderSpendingLimitExceeded = "spend_limit_exceeded",
}

export const INCREASE_SPENDING_LIMITS_FORM_URL =
  "https://forms.gle/Z7sRcPWCAonf5cAB7";

export const ACCOUNTS_FEATURE_FLAG_TOGGLES = [
  "enableTransactionCsvDownload",
  "enableTopUpInvoices",
] as Array<"enableTransactionCsvDownload">;

export const WEBHOOK_FEEDBACK_FORM_URL =
  "https://docs.google.com/forms/d/e/1FAIpQLSf67FWykTTMojSujK89XlDQNpH6qPfqEeAtB998HcA3DV6ovQ/viewform";

export const USER_ROLES_OPTIONS = [
  {
    value: "admin",
    label: "Admin",
    title: "Admin (full access)",
    description:
      "Allows user to access all features. Has rights to add or remove team members.",
  },
  {
    value: "service_manager",
    label: "Service manager",
    description:
      "Can view available merchants and apply for new ones. They can also view and cancel existing orders and download a history of transactions and statements.",
  },
  {
    value: "finance_manager",
    label: "Finance manager",
    description:
      "Can download a history of transactions and statements, make FX transfers and edit card details. They can also view and cancel existing orders.",
  },
  {
    value: "viewer",
    label: "Viewer",
    description:
      "Has view-only permissions to see existing orders, merchants, balances, transactions and team members.",
  },
  {
    value: "service_agent",
    label: "Service agent",
    description:
      "Can see a history of transactions and list of merchants. They can also view and cancel existing orders.",
  },
];

export const USER_ROLES = {
  [UserRoleEnum.OWNER]: {
    name: "Owner",
    description:
      "Cannot be removed from the platform by other users, and has permission to make decisions on and alter the account.",
  },
  [UserRoleEnum.ADMIN]: {
    name: "Admin",
    description:
      "Default role assigned to users invited to the platform by an Owner.",
  },
  [UserRoleEnum.SERVICE_AGENT]: {
    name: "Service Agent",
    description:
      "Allows the ability to view and cancel existing orders, view available products and view the transaction history on the account.",
  },
  [UserRoleEnum.VIEWER]: {
    name: "Viewer",
    description:
      "Allows the ability to view existing orders, available products, and transaction history on the account.",
  },
  [UserRoleEnum.FINANCE_MANAGER]: {
    name: "Finance Manager",
    description:
      "Allows users to view and manage all finance functions such as top-ups, cards, FX, and allows full access to account history.",
  },
  [UserRoleEnum.SERVICE_MANAGER]: {
    name: "Service Manager",
    description:
      "Allows users to view and manage all orders without the ability to create new orders. Includes the ability to FX funds between balances without the ability to add funds or new cards, submit applications for new payouts, and visibility of transaction history.",
  },
};

export const BANNER_DATA: BannerData = {
  [RegionEnum.NorthAmerica]: {
    heading: "Purchase by October 15th and receive bonus 5% discount",
    subheading:
      "Choose from over 600,000 Hotels Worldwide and all the major hotel brands",
    merchantName: "Grand Hotel Card",
    productCode: "GHC-US",
    backgroundImageId: "",
    additionalImageId: "",
    country: "US",
  },
  [RegionEnum.UK]: {
    heading: "Purchase by October 15th and receive bonus 5% discount",
    subheading:
      "Choose from over 600,000 Hotels Worldwide and all the major hotel brands",
    merchantName: "Grand Hotel Card",
    productCode: "GHC-GB",
    backgroundImageId: "",
    additionalImageId: "",
    country: "GB",
  },
  [RegionEnum.Europe]: {
    heading: "Purchase by October 15th and receive bonus 5% discount",
    subheading:
      "Choose from over 600,000 Hotels Worldwide and all the major hotel brands",
    merchantName: "Grand Hotel Card",
    productCode: "GHC-FR",
    backgroundImageId: "",
    additionalImageId: "",
    country: "FR",
  },
  [RegionEnum.MiddleEast]: {
    heading: "",
    subheading: "",
    merchantName: "",
    productCode: "",
    backgroundImageId: "",
    additionalImageId: "",
    country: "",
  },
  [RegionEnum.Asia]: {
    heading: "",
    subheading: "",
    merchantName: "",
    productCode: "",
    backgroundImageId: "",
    additionalImageId: "",
    country: "",
  },

  [RegionEnum.Africa]: {
    heading: "",
    subheading: "",
    merchantName: "",
    productCode: "",
    backgroundImageId: "",
    additionalImageId: "",
    country: "",
  },
  [RegionEnum.Default]: {
    heading: "",
    subheading: "",
    merchantName: "",
    productCode: "",
    backgroundImageId: "",
    additionalImageId: "",
    country: "",
  },
};

export enum RosApiErrorTypes {
  ORDERING_RESTRICTED = "ordering_restricted",
}

export const DEFAULT_PAYOUTS_SENT_PAGE_SIZE = 100;
