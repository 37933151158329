import {
  UsersService as ScionUserService,
  UserSecret,
  ApiError as ScionApiError,
} from "@workspace/open-api/value-link-ordering-service";
import {
  identifyUser as identifyMixpanelUser,
  initMixpanel,
} from "@two-ui/mixpanel";
import { setSentryUserContext } from "./sentry";
import store from "./store";
import {
  UserService as CmsUserService,
  CustomerService as CmsCustomerService,
  User as CmsUser,
  ApiError as CmsApiError,
  CustomerExistsResponse,
  UserService,
  OnboardingStateResponse,
} from "@workspace/open-api/cms";
import { UserOnboardingState, UserFromToken } from "./types";
import { getDomainFromEmail } from "./util/string";
import { getUser } from "./auth0";
import { User as Auth0User } from "@auth0/auth0-spa-js";
import { StatusCodes as HttpStatusCodes } from "http-status-codes";
import { IntercomChatClient } from "./services/intercom";
import {
  PendingCustomerService,
  OnboardingTaskStateEnum,
  PendingCustomerApprovalStatusEnum,
} from "@workspace/open-api/cms";
import {
  identifyUser as identifyHotjarUser,
  initializeHotjar,
} from "@two-ui/services/hotjar";
import {
  identifyUser as identifyHubspotUser,
  addHubspotScript,
} from "@two-ui/hubspot";

/**
 * Gets user using authentication token
 * @returns CMS User. Returns null if user does not exists
 */
export async function getUserFromCmsByToken(): Promise<UserFromToken> {
  try {
    const user = await CmsUserService.getUserMe();
    return user;
  } catch (exception) {
    if (exception instanceof CmsApiError) {
      console.debug("Cannot link scion user to current logged in user");
      if (exception.status === 404) {
        return null;
      }
    }
  }
}

export async function getUserById(id: string): Promise<UserSecret | undefined> {
  try {
    return await ScionUserService.getUser(id);
  } catch (exception) {
    if (exception instanceof ScionApiError) {
      console.debug("Cannot find scion user");
      return;
    }
  }
}

export async function initializeStoreAndPluginsFromUser(
  user: CmsUser,
  auth0User: Auth0User,
  auth0JwtToken: string
) {
  store.setStateFromAuth0User(auth0User);
  store.setStatePermissionsFromAuth0JwtToken(auth0JwtToken);
  store.setIsStaff(user.isStaff);
  store.setUseBalancesTnCAccepted(!!user.options?.useBalancesTncAccepted);
  store.setDataProcessingTncAccepted(
    user.options?.dataProcessingTncAccepted || null
  );
  store.setUserPropertiesPopulated(true);
  if (user.brandApprovalAccessToken) {
    store.setBrandApprovalAccessToken(user.brandApprovalAccessToken);
  }
  setSentryUserContext(user);
  IntercomChatClient.initialize();
}

/**
 * Checks if customer exists for current authenticated user domain
 * @returns Promise for object containing customer id. Return null if customer does not exist.
 * If 200 but no customer id in response, it means customer pending
 */
export async function getCustomerForAuthenticatedUserEmailDomain(): Promise<CustomerExistsResponse | null> {
  try {
    const user = await getUser();
    const emailDomain = getDomainFromEmail(user?.email || "");
    // Bypass this check for certain domains.
    // Emails from these domains are allowed to create multiple customers.
    if (emailDomain === "runa.io") return null;
    if (emailDomain === "runa.test") return null;
    const customer = await CmsCustomerService.getCustomerExistsByDomain(
      emailDomain
    );
    return customer;
  } catch (exception) {
    if (exception instanceof CmsApiError) {
      if (exception.status === 404) {
        return null;
      }
    }
    throw exception;
  }
}

export async function getPendingCustomerOnboardingState(): Promise<OnboardingStateResponse | null> {
  try {
    return await PendingCustomerService.getOnboardingState();
  } catch (exception) {
    if (exception instanceof CmsApiError) {
      if (exception.status === 404) {
        return null;
      }
    }
    throw exception;
  }
}

export async function getUserAndTheirOnboardingState() {
  const user = await getUserFromCmsByToken();
  return {
    user,
    state: await getOnboardingStateForAuthenticatedUser(user),
  };
}

/**
 * Checks if there is a pending invite with CMS using auth token and retuns a boolean or null.
 * @returns {boolean | null} true if invite exists, false if not. Returns null if the invite has expired or has been used (user exists)
 */
export async function invitePendingForAuthenticatedUser(): Promise<
  boolean | null
> {
  try {
    await CmsUserService.getCustomerUserInvite();
  } catch (ex) {
    if (ex instanceof CmsApiError) {
      if (ex.status == HttpStatusCodes.UNPROCESSABLE_ENTITY) {
        return null;
      } else if (
        ex.status == HttpStatusCodes.NOT_FOUND ||
        ex.status == HttpStatusCodes.BAD_REQUEST
      ) {
        return false;
      }
      throw ex;
    }
  }
  return true;
}

export async function activateInvitedUser() {
  try {
    await UserService.activateUser();
  } catch (error) {
    alert("There was a problem activating the invited user");
    throw error;
  }
}

export function urlIncludesParamForOnboarding() {
  return window.location.href.includes("customer_onboarding=true");
}

export async function getOnboardingStateForAuthenticatedUser(
  user: UserFromToken
): Promise<UserOnboardingState> {
  if (user === null) {
    // If there is a pending invite or 'customer_onboarding' query param present, return state that indicates user creation is required
    if (
      (await invitePendingForAuthenticatedUser()) === true ||
      urlIncludesParamForOnboarding()
    ) {
      return UserOnboardingState.UserCreationRequired;
    } else {
      // invite has expired
      return UserOnboardingState.UserInviteExpired;
    }
  }
  if (!user) {
    // No invite, no customer = fresh customer signup
    return UserOnboardingState.UnknownState;
  }
  const activeCustomerExistsForUser = !!user.customerIds.length;
  if (activeCustomerExistsForUser) {
    return UserOnboardingState.BelongsToActiveCustomer;
  }
  const customerOnboardingState = await getPendingCustomerOnboardingState();
  if (customerOnboardingState === null) {
    return UserOnboardingState.CustomerCreationRequired;
  }
  if (
    customerOnboardingState.onboardingTaskState !==
    OnboardingTaskStateEnum.COMPLETE
  ) {
    return UserOnboardingState.CustomerOnboardingTaskNotComplete;
  }
  if (
    customerOnboardingState.approvalStatus ===
    PendingCustomerApprovalStatusEnum.APPROVED
  ) {
    return UserOnboardingState.CustomerWithDomainAlreadyExists;
  }
  if (
    customerOnboardingState.approvalStatus ===
    PendingCustomerApprovalStatusEnum.PENDING
  ) {
    return UserOnboardingState.CustomerApprovalRequired;
  }
  if (
    customerOnboardingState.approvalStatus ===
    PendingCustomerApprovalStatusEnum.DECLINED
  ) {
    // treat declined customer as pending customer.
    return UserOnboardingState.CustomerApprovalRequired;
  }
  return UserOnboardingState.UnknownState;
}

export async function initializeTracking(user: CmsUser) {
  initMixpanel();
  identifyMixpanelUser(user);
  // Only enable for prod when we don't support sandbox
  if (import.meta.env.PROD) {
    await addHubspotScript();
    identifyHubspotUser(user);
    await initializeHotjar();
    identifyHotjarUser(user);
  }
}
